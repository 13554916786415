<template>
  <b-nav-item-dropdown
    no-caret
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <!-- <div class="d-lg-flex d-none user-nav">
                <p class="mb-0 user-name font-weight-bolder">
                    {{ user.name }}
                </p>
                <span class="user-status" v-if="roles.length">{{ role[0].name }}</span>
            </div> -->
      <avatar :user="user" />
    </template>

    <b-dropdown-item
      :href="route('account')"
      link-class="d-flex align-items-center"
      @click.prevent="$inertia.get(route('account'))"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Pengaturan Akun</span>
    </b-dropdown-item>

    <b-dropdown-item
      :href="route('logout')"
      link-class="d-flex align-items-center"
      @click.prevent="$inertia.post(route('logout'))"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="isImpersonated"
      href="/impersonate"
      link-class="d-flex align-items-center"
      @click.prevent="leaveImpersonate()"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Leave Impersonate</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import Avatar from '@/Components/Avatar.vue'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        Avatar,
    },

    data() {
        return {
            //
        }
    },

    computed: {
        user() {
            return this.$page.props.auth.user
        },
        roles() {
            return this.user.roles
        },
        isImpersonated() {
            return !!this.user.impersonated
        },
    },

    methods: {
        leaveImpersonate() {
            this.$inertia.delete('/impersonate')
        },
    },
}
</script>
