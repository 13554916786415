<template>
  <ul
    :id="wrapperId"
    :class="wrapperClass"
  >
    <!-- dashboard -->
    <li
      class="nav-item"
      :class="{ 'sidebar-group-active active': route().current('dashboard') }"
    >
      <inertia-link
        as="b-link"
        class="nav-link d-flex align-items-center"
        :href="route('dashboard')"
      >
        <feather-icon
          size="14"
          icon="HomeIcon"
        />
        <span class="menu-title text-truncate">Dashboard</span>
      </inertia-link>
    </li>

    <!-- job -->
    <li
      class="nav-item"
      :class="{ 'sidebar-group-active active': route().current('opportunities.job.*') }"
    >
      <inertia-link
        as="b-link"
        class="nav-link d-flex align-items-center"
        :href="route('opportunities.job.index')"
      >
        <feather-icon
          size="14"
          icon="BriefcaseIcon"
        />
        <span class="menu-title text-truncate">Lowongan</span>
      </inertia-link>
    </li>

    <!-- faq -->
    <li
      class="nav-item"
      :class="{ 'sidebar-group-active active': route().current('faq.view') }"
    >
      <inertia-link
        as="b-link"
        :href="route('faq.view')"
        class="nav-link d-flex align-items-center"
      >
        <feather-icon
          size="14"
          icon="HelpCircleIcon"
        />
        <span class="menu-title text-truncate">Pertanyaan yang Sering Ditanyakan</span>
      </inertia-link>
    </li>

    <!-- applications -->
    <li
      class="nav-item"
      :class="{ 'sidebar-group-active active': route().current('applications.*') }"
    >
      <inertia-link
        as="b-link"
        class="nav-link d-flex align-items-center"
        :href="route('applications.index')"
      >
        <feather-icon
          size="14"
          icon="FileTextIcon"
        />
        <span class="menu-title text-truncate">Riwayat Lamaran</span>
        <b-badge
          v-if="countPendingJobApplications"
          pill
          class="ml-50"
          variant="danger"
        >
          {{ countPendingJobApplications }}
        </b-badge>
      </inertia-link>
    </li>
  </ul>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        BLink,
        BBadge,
    },

    props: {
        wrapperId: String,
        wrapperClass: String,
        vertical: Boolean,
        horizontal: Boolean,
    },

    data() {
        return {
            countPendingJobApplications: 0,
        }
    },

    mounted() {
        this.fetchPendingJobApplications()
    },

    methods: {
        fetchPendingJobApplications() {
            this.$http
                .get('/api/confirm-application/count')
                .then(({ data }) => data)
                .then(({ count }) => (this.countPendingJobApplications = count))
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
