<template>
  <div class="navbar-container main-menu-content">
    <div class="navbar-container main-menu-content">
      <menu-items
        wrapper-id="main-menu-navigation"
        wrapper-class="nav navbar-nav"
        horizontal
      />
    </div>
  </div>
</template>

<script>
import MenuItems from '@/Components/MenuItems.vue'

export default {
    components: {
        MenuItems,
    },

    methods: {
        //
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
