import '../@core/scss/core.scss'

import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltipPlugin as TooltipPlugin } from 'bootstrap-vue'
// import VueCompositionAPI from '@vue/composition-api'
import VueMeta from 'vue-meta'
import { createInertiaApp } from '@inertiajs/vue2'
import LayoutVertical from '@/layouts/vertical/LayoutVertical.vue'
import LayoutHorizontal from '@/layouts/horizontal/LayoutHorizontal.vue'

// import router from './router'
import store from './store'
// import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/axios'
import '@/libs/sweet-alerts'
import '@/libs/moment'
import '@/libs/vue-select'
import '@/libs/transalation'
import '@/libs/sentry'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)

// Composition API
// Vue.use(VueCompositionAPI)

// Meta
Vue.use(VueMeta)

// Route helper function
Vue.mixin({ methods: { route: window.route } })

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

createInertiaApp({
    progress: {
        color: '#3056d3'
    },
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue')
        const page = pages[`./Pages/${name}.vue`]().then((module) => module.default)
        if (name.startsWith('Admin/')) {
            page.layout = page.layout || LayoutVertical
        } else {
            page.layout = page.layout || LayoutHorizontal
        }

        return page
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin)

        new Vue({
            store,
            metaInfo: {
                titleTemplate: (title) => (title ? `${title} - JobsDKU` : 'JobsDKU'),
            },
            render: (h) => h(App, props),
        }).$mount(el)
    },
})
