export default {
    data() {
        return {
            user: this.$page.props.auth.user,
        }
    },

    computed: {
        roles() {
            return this.user.roles || []
        },
        isStaff() {
            return this.roles.find((role) => role.name == 'user')
        },
        isOperator() {
            return this.roles.find((role) => role.name == 'member')
        },
    },
}
