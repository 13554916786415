<template>
  <b-nav-item-dropdown
    no-caret
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="mb-0 mr-auto notification-title">
          Notifikasi
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <!-- <b-link v-for="notification in notifications" :key="notification.subtitle">
                <b-media>
                    <template #aside>
                        <b-avatar
                            size="32"
                            :src="notification.avatar"
                            :text="notification.avatar"
                            :variant="notification.type"
                        />
                    </template>
                    <p class="media-heading">
                        <span class="font-weight-bolder">
                            {{ notification.title }}
                        </span>
                    </p>
                    <small class="notification-text">{{ notification.subtitle }}</small>
                </b-media>
            </b-link> -->

      <!-- System Notifications -->
      <template v-if="notifications.length">
        <b-link
          v-for="notification in notifications"
          :key="notification.id"
        >
          <b-media :class="{ 'bg-light': !notification.read_at }">
            <template #aside>
              <b-avatar
                size="32"
                variant="light-secondary"
              >
                <feather-icon icon="BellIcon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.data.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.data.message }}</small>
            <div>
              <small class="notification-text">
                {{ notification.created_at | moment('from') }}
              </small>
            </div>
          </b-media>
        </b-link>
      </template>

      <b-link v-else>
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">Tidak ada notifikasi</span>
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="unreadCount"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="markAllAsRead()"
      >
        Tandai semua sudah dibaca
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BMedia, BLink, BAvatar, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from '@core/components/VuePerfectScrollbar.vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BNavItemDropdown,
        BMedia,
        BLink,
        BAvatar,
        VuePerfectScrollbar,
        BButton,
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            notifications: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
        }
    },

    computed: {
        unreadCount() {
            return this.notifications.reduce((acc, notif) => acc + (notif.read_at === null ? 1 : 0), 0)
        },
    },

    mounted() {
        this.getNotifications()
    },

    methods: {
        async getNotifications() {
            await this.$http.get('/api/notification').then(({ data }) => (this.notifications = data))
        },
        async markAllAsRead() {
            await this.$http.post('/api/notification/read').then(() => this.getNotifications())
        },
    },
}
</script>
