import Vue from 'vue'
import Lang from 'lang.js'

const locale = window.defaultLocale
const fallback = window.fallbackLocale
const messages = window.languageMessages

Vue.prototype.t = new Lang({
    messages,
    locale,
    fallback,
})
