export default [
    {
        title: 'Dashboard',
        href: '/dashboard',
        icon: 'HomeIcon',
        inertia: true,
        name: 'dashboard',
    },
    { header: 'Master' },
    {
        title: 'Member',
        href: '/admin/member',
        icon: 'UsersIcon',
        inertia: true,
        name: 'member.*',
    },
    {
        title: 'Kandidat Staf',
        href: '/admin/candidate',
        icon: 'AwardIcon',
        inertia: true,
        name: 'candidate.*',
    },
    {
        title: 'Perusahaan',
        href: '/admin/company',
        icon: 'BriefcaseIcon',
        inertia: true,
        name: 'company.*',
    },
    {
        title: 'Lowongan',
        href: '/admin/job',
        icon: 'FileTextIcon',
        inertia: true,
        name: 'job.*',
    },
    {
        title: 'Transaksi',
        href: '/admin/transaction',
        icon: 'DollarSignIcon',
        inertia: true,
        name: 'transaction.*',
    },
    {
        title: 'Kontak Perusahaan',
        href: '/admin/contact',
        icon: 'BookOpenIcon',
        inertia: true,
        name: 'contact.*',
    },
    { header: 'News' },
    {
        title: 'Artikel',
        href: '/admin/post',
        icon: 'RssIcon',
        inertia: true,
        name: 'post.*',
    },
    {
        title: 'FAQ',
        href: '/admin/faq',
        icon: 'HelpCircleIcon',
        inertia: true,
        name: 'faq.*',
    },
    {
        title: 'Testimoni',
        href: '/admin/testi',
        icon: 'SmileIcon',
        inertia: true,
        name: 'testi.*',
    },
    {
        title: 'Slider',
        href: '/admin/slider',
        icon: 'ColumnsIcon',
        inertia: true,
        name: 'slider.*',
    },
    { header: 'Lainnya' },
    {
        title: 'Pengaturan',
        href: '/admin/settings',
        icon: 'SettingsIcon',
        inertia: true,
        name: 'settings.*',
    },
    // {
    //     title: 'Admin',
    //     href: '/admin/admin',
    //     icon: 'UserCheckIcon',
    //     inertia: true,
    // },
    // {
    //     title: 'Halaman',
    //     href: '/admin/faq',
    //     icon: 'UserCheckIcon',
    //     inertia: true,
    // },

    // {
    //     title: 'Has Children',
    //     icon: 'FileIcon',
    //     children: [
    //         {
    //             title: 'Children One',
    //             href: 'welcome',
    //             icon: 'FileIcon',
    //         },
    //     ],
    // },
]
