export default [
    {
        title: 'Dashboard',
        href: '/dashboard',
        icon: 'HomeIcon',
        inertia: true,
        name: 'dashboard',
    },
    {
        title: 'Lowongan',
        href: 'jobs',
        icon: 'BriefcaseIcon',
        inertia: true,
    },
    {
        title: 'Pertanyaan yang Sering Ditanyakan',
        href: 'faq',
        icon: 'HelpCircleIcon',
        inertia: true,
    },
    {
        title: 'Riwayat Lamaran',
        href: '/applications',
        icon: 'FileTextIcon',
        inertia: true,
        name: 'applications.*',
    },
    // {
    //     title: 'Has Children',
    //     header: 'Has Children',
    //     icon: 'FileIcon',
    //     children: [
    //         {
    //             title: 'Second Page',
    //             href: 'welcome',
    //             icon: 'FileIcon',
    //             inertia: true,
    //         },
    //         {
    //             title: 'Facebook',
    //             href: 'https://facebook.com',
    //             icon: 'FileIcon',
    //             inertia: false,
    //         },
    //     ],
    // },
]
