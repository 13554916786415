<template>
    <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
            <!-- <div class="d-lg-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ user.name }}
                </p>
                <span class="user-status" v-if="roles.length">{{ role[0].name }}</span>
            </div> -->
            <avatar :user="user" />
        </template>

        <b-dropdown-item :to="{ name: 'pages-profile' }" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
            :href="route('logout')"
            link-class="d-flex align-items-center"
            @click.prevent="$inertia.post(route('logout'))"
        >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import Avatar from '@/Components/Avatar.vue'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        Avatar,
    },

    data() {
        return {
            //
        }
    },

    computed: {
        user() {
            return this.$page.props.auth.user
        },
        roles() {
            return this.user.roles
        },
    },

    methods: {},
}
</script>
