<template>
    <li
        class="nav-item"
        :class="{
            active: isActive,
            disabled: item.disabled,
        }"
    >
        <component :is="resolveIsInertiaUrl(item)" v-bind="linkProps" class="d-flex align-items-center">
            <feather-icon :icon="item.icon || 'CircleIcon'" />
            <span class="menu-title text-truncate">{{ item.title }}</span>
            <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="ml-auto mr-1">
                {{ item.tag }}
            </b-badge>
        </component>
    </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import { resolveIsInertiaUrl } from '@core/layouts/utils'

export default {
    components: {
        BLink,
        BBadge,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { linkProps } = useVerticalNavMenuLink(props.item)

        return {
            linkProps,
            resolveIsInertiaUrl,
        }
    },

    data() {
        return {
            isActive: false,
        }
    },

    mounted() {
        this.$once(
            'hook:destroyed',
            this.$inertia.on('navigate', () => this.currentRouteActive())
        )
    },

    methods: {
        currentRouteActive() {
            if (this.item.name) return (this.isActive = this.route().current(this.item.name))
        },
    },
}
</script>
