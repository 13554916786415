<template>
  <b-avatar
    :size="size"
    variant="light-primary"
  >
    <b-img
      :src="avatarUrl"
      fluid
    />
  </b-avatar>
</template>

<script>
import { BAvatar, BImg } from 'bootstrap-vue'

export default {
    components: {
        BAvatar,
        BImg,
    },

    props: {
        size: {
            type: [Number, String],
            default: 40,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    computed: {
        avatarUrl() {
            return (
                this.user.avatar_url ??
                `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    this.user.name
                )}&color=3056d3&background=EBF4FF&size=128`
            )
        },
    },
}
</script>
