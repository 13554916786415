<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <!-- <div class="align-items-center flex-grow-1">
        </div> -->

        <!-- Right Col -->
        <b-navbar-nav class="ml-auto nav align-items-center">
            <notification-dropdown />
            <user-dropdown />
        </b-navbar-nav>
    </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
import NotificationDropdown from '@/Components/NotificationDropdown.vue'
import UserDropdown from '@/Components/UserDropdown.vue'
import mixinOnboardingState from '@/Components/Onboarding/mixinOnboardingState'

export default {
    components: {
        BLink,

        BNavbarNav,
        // Bookmarks,
        // DarkToggler,
        // SearchBar,
        NotificationDropdown,
        UserDropdown,
    },

    mixins: [mixinOnboardingState],

    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
}
</script>
