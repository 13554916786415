<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            © {{ new Date().getFullYear() }}
            PT. Dhasnarindo Karya Utama
        </span>

        <!-- <span class="float-md-right d-none d-md-block">
            Hand-crafted &amp; Made with
            <feather-icon icon="HeartIcon" size="21" class="stroke-current text-danger" />
        </span> -->
    </p>
</template>

<script>
export default {
    data() {
        return {
            //
        }
    },
}
</script>
