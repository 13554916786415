<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul
      v-if="!hideMenuButton"
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-badge
        v-if="environment && environment !== 'production'"
        variant="light-warning"
        class="text-uppercase"
      >
        {{ environment }}
      </b-badge>
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="ml-auto nav align-items-center">
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BBadge } from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UserDropdown from '@/Components/UserDropdown.vue'
import NotificationDropdown from '@/Components/NotificationDropdown.vue'

export default {
    components: {
        BLink,
        BNavbarNav,
        BBadge,
        UserDropdown,
        NotificationDropdown,

        // Navbar Components
        // DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
        hideMenuButton: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        user() {
            return this.$page.props.auth.user
        },
        roles() {
            return this.user.roles
        },
        environment() {
            return import.meta.env.VITE_APP_ENV
        },
    },
}
</script>
